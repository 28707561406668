 @import url('https://fonts.google.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

 * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
 }

 html {
  scroll-behavior: smooth;
 }

 body {
  margin: 0px !important;
  padding: 0; 
  font-family: 'Montserrat', sans-serif;
 }

 ::-webkit-scrollbar {
  width: 4px;
  height: 80px;
 }

 ::-webkit-scrollbar-track {
  background: #222A35;
 }

 ::-webkit-scrollbar-thumb{
  background: #575C66;
  border-radius: 6px;
 }

 .shape-bottom {
    position: static;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.shape-bottom svg {
    position: relative;
    display: block;
    width: calc(124% + 1.3px);
    height: 120px;
}

.shape-bottom .shape-fill {
    fill: #854CE6B3;
}

.shape-top {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.shape-top svg {
    position: relative;
    display: block;
    width: calc(124% + 1.3px);
    height: 120px;
}

.shape-top .shape-fill {
    fill: #854CE6B3;
}